<div class="header-container">
  <div>
    <p class="content-secondary-color no-margin body2--medium">
      {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_READY_TO_SELL' | translate }}
    </p>
    <div class="full-height" *ngIf="!isDuplicateAccount; else isDuplicate">
      <section class="product-actions">
        <div class="product-availability-wrapper product-ordering-actions" *ngIf="!preOrderV3Flag">
          <p
            *ngIf="
              !userHasStockAvailability ||
              productAvailability === productAvailabilityEnum.available_for_preorder
            "
            class="product-availability"
          >
            {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.PRODUCT_STATUS' | translate }} :
            <span class="product-availability-text" [ngStyle]="{ color: availabilityTextColor }">{{
              productAvailabilityTranslationKey | translate
            }}</span>
          </p>
          <button
            *ngIf="bulkPreOrderButtonIsVisible"
            class="action-button bulk-preorder-button clickable body2--bold"
            (click)="onPreOrderRequestClick()"
          >
            {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.BULK_PRE_ORDER' | translate }}
          </button>
        </div>
        <section class="product-ordering-actions">
          <button
            class="action-button add-to-cart-button clickable body2--bold"
            (click)="onAddToCart()"
            [disabled]="
              productAvailability === productAvailabilityEnum.not_available || !productIsOrderable
            "
          >
            <img class="add-to-cart-icon" src="assets/img/cart.svg" />
            {{ 'PRODUCTS_PAGE.ADD_TO_CART' | translate }}
          </button>
          <button
            class="action-button order-now-button clickable body2--bold"
            (click)="onOrderNow()"
            [disabled]="
              productAvailability === productAvailabilityEnum.not_available ||
              !productIsOrderable ||
              isMarketClosed
            "
            data-test-id="order-now-btn"
          >
            {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ORDER_NOW' | translate }}
          </button>
        </section>
        <section class="favorite-and-share-section">
          <button
            class="add-to-catalog-button clickable body2--bold"
            (click)="onToggleIsCataloged()"
          >
            <img class="icon-style" *ngIf="!productIsCataloged" src="assets/img/heart.svg" />
            <img class="icon-style" *ngIf="productIsCataloged" src="assets/img/heart-active.svg" />
            {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ADD_TO_CATALOG' | translate }}
          </button>
        </section>
        <section class="preorder-v3-section" *ngIf="preOrderV3Flag && !isBundle">
          <img src="assets/img/locked.svg" alt="lock" />
          <div>
            <p class="body1--bold content-main">
              {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.PREORDER.TITLE' | translate }}
            </p>
            <p class="body2--regular content-medium">
              {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.PREORDER.DESCRIPTION' | translate }}
            </p>
          </div>
          <button (click)="onPreOrderRequestClick()" class="preorder-v3-section__CTA">
            {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.PREORDER.RESERVE' | translate }}
          </button>
        </section>
      </section>
    </div>
  </div>
</div>
<ng-template #isDuplicate>
  <div class="duplicate-account">
    <img src="assets/img/product-details-icons/lock.svg" alt="" />
    {{ trans('wallet.duplicateAccountError') }}
  </div>
</ng-template>
